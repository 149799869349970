<mat-toolbar color="primary">
  <button mat-icon-button (click)="toggleSidebar.emit()">
    <mat-icon>menu</mat-icon>
  </button>
  &nbsp;
  <span>
    <a [routerLink]="['/']" routerLinkActive="router-link-active">{{ title }}</a>
  </span>
  <div fxFlex fxLayout fxLayoutAlign="flex-end" fxHide.xs>
    <ul fxLayout fxLayoutGap="20px" class="navigation-items" *ngIf="menu">
      <li *ngFor="let menuItem of menu.items">
        <a [href]="menuItem.link" target="_new">
          <span class="label">{{ menuItem.label }}</span>
        </a>
      </li>
    </ul>
  </div>
</mat-toolbar>
