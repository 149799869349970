<div
  class="editor"
  #editorInstance
  ngxEditorJS
  [id]="holder"
  [holder]="holder"
  [autofocus]="autofocus"
  [autosave]="autosave"
  [hideToolbar]="hideToolbar"
  [initialBlock]="initialBlock"
  [minHeight]="minHeight"
  [blockPlaceholder]="blockPlaceholder"
  [sanitizer]="sanitizer"
  [blocks]="blocks"
  [excludeTools]="excludeTools"
  (hasSaved)="hasSaved.emit($event)"
  (isTouched)="isTouched.emit($event)"
  (isFocused)="isFocused.emit($event)"
  (hasChanged)="hasChanged.emit($event)"
  (isReady)="isReady.emit($event)"
></div>
