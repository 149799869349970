<div class="container">
  <mat-card class="application-card">
    <mat-card-header>
      <mat-card-title><code>@tinynodes/ngx-editor</code> Demo</mat-card-title>
      <mat-card-subtitle
        >A collection of demos for the <code>@tinynodes/ngx-editor</code> module with example code and links to API docs.</mat-card-subtitle
      >
    </mat-card-header>
    <mat-card-content>
      <p>
        The demos in this section are for the
        <a href="https://www.npmjs.com/package/@tinynodes/ngx-editorjs" target="_new"><code>@tinynodes/ngx-editorjs</code> module.</a>
      </p>
      <p>
        Each demo provides a way to use the component in an application or form in Angular. It can be used as a basic component, used with
        Angular forms or Material forms.
      </p>
      <p>Each demo shows the components providing a <a href="https://editorjs.io">EditorJS</a> instance.</p>
    </mat-card-content>
    <mat-card-actions>
      <a mat-raised-button color="primary" [routerLink]="['/ngx-editorjs-demo/angular-component']">Angular Component Demo</a>
      <a mat-raised-button color="primary" [routerLink]="['/ngx-editorjs-demo/angular-form']">Angular Material Form Demo</a>
    </mat-card-actions>
  </mat-card>
</div>
