<div class="container">
  <mat-card class="homepage-card">
    <mat-card-header>
      <mat-card-title>Welcome to the Tinynodes Demo Site</mat-card-title>
      <mat-card-subtitle>A collection of Angular and TypeScript libraries for the web</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>
        This site contains demos of components released under <code>@tinynodes</code> on <code>npm</code>, you can find a list of them on
        the menu to the left.
      </p>
      <p>You can also visit the sites below for additional documentation and code:</p>
      <mat-list *ngIf="(menu | async); let menuItems">
        <mat-list-item *ngFor="let menu of menuItems.items">
          <a mat-raised-button color="primary" href="{{ menu.link }}" target="_new">
            <span class="label">{{ menu.label }}</span>
          </a>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
