<div class="container">
  <mat-card class="application-card">
    <mat-card-header>
      <mat-card-title><code>@tinynodes/ngx-editor</code> Demo</mat-card-title>
      <mat-card-subtitle>A single page demo of the <code>EditorJS</code> component</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ngx-editorjs-demo-info title="ngx-editorjs Information" [menu]="menu | async" [body]="infoTemplate"></ngx-editorjs-demo-info>
    </mat-card-content>

    <mat-card-content>
      <mat-card-actions>
        <h3 mat-card-title>Demo</h3>
        <button mat-raised-button (click)="save()">Save</button>
        <button mat-raised-button (click)="clear()">Clear</button>
        <button mat-raised-button *ngIf="(blocks | async); let b" (click)="reset()">Reset</button>
      </mat-card-actions>
      <mat-card-content>
        <mat-list>
          <mat-list-item>Ready: {{ ngxEditorJS.isReady | async }}</mat-list-item>
          <mat-list-item
            >Has Changed:
            <span *ngIf="(ngxEditorJS.hasChanged | async); let change">{{ change.time }}</span>
            <span *ngIf="!(ngxEditorJS.hasChanged | async)">0</span>
          </mat-list-item>
          <mat-list-item>Touched: {{ ngxEditorJS.isTouched | async }}</mat-list-item>
          <mat-list-item>Focused: {{ ngxEditorJS.isFocused | async }}</mat-list-item>
          <mat-list-item>Saved: {{ ngxEditorJS.hasSaved | async }}</mat-list-item>
        </mat-list>
      </mat-card-content>
      <ngx-editorjs #ngxEditorJS [holder]="holder"></ngx-editorjs>
    </mat-card-content>
    <mat-card-content>
      <mat-card class="output-card">
        <mat-card-header>
          <mat-card-title>Output</mat-card-title>
          <mat-card-subtitle>Data from the <code>EditorJS</code> instance</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <pre id="output" style="width: 100%; overflow: scroll; word-wrap: break-word;">{{ asJSON | async }}</pre>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>
</div>

<ng-template #infoTemplate>
  <p>
    Below is an instance of an <code>EditorJS</code> block editor. Click on the area and the block tool will appear, or you can just start
    typing.
  </p>
  <p>
    The default block is a <code>&lt;p></code> paragraph, you can also add header <code>&lt;h1> - &lt;h6></code> blocks, list with
    <code>&lt;ol> / &lt;li></code> and <code>&lt;code></code> blocks.
  </p>

  <p>
    You can also paste in an <code>&lt;img></code> url into the editor and it will start the <code>SimpleImage</code> plugin which provides
    an <code>&lt;img></code> tag pointing to the image, and with some additional controls.
  </p>
  <p>
    When saving check the output panel below to see the data results.
  </p>
</ng-template>
