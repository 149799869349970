<tinynodes-nav-bar title="Tinynodes Angular Demos" [menu]="mainMenu | async" (toggleSidebar)="toggleSidebar()"></tinynodes-nav-bar>
<mat-sidenav-container class="application">
  <mat-sidenav #sidenav role="navigation" mode="side" opened>
    <tinynodes-sidebar [projectsMenu]="projectsMenu | async" [openSourceMenu]="openSourceMenu | async"></tinynodes-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
